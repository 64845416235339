import React from "react"
import Header from "../components/header"
import { graphql, Link, useStaticQuery } from "gatsby"
import "../styles/common.less"
export default function Home() {
  const sdata = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            img
          }
        }
      }
    `
  )
  return (
    <div style={{ color: `purple` }}>
      <Link to="/contact/">Contact</Link>
      <Header headerText={sdata.site.siteMetadata.title} />
      <p>auto deploy test!!</p>
      <img src={sdata.site.siteMetadata.img} alt="" />
    </div>
  )
}